export default {
    saveAttributes(data) {
        return window.api.post('/attribute_map/create', data);
    },

    getAttributesMaps() {
        return window.api.get('/attribute_map/all');
    },

    deleteAttributeMap(id) {
        return window.api.delete(`/attribute_map/delete/${id}`);
    }
}