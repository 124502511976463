<template>
<a-row :gutter="25">
<a-col :md="24" :xs="24">
    <sdCards title="Add max 7 attributes to map">
        <SelectWrapperStyle>
            <a-select 
                v-model:value="attributesValues"
                style="width: 520px" 
                mode="multiple"
                :token-separators="[',']"
            >
                <a-select-option 
                    v-for="(attribute, key) in attributes" :key="attribute">
                    {{ attribute }}
                </a-select-option>
            </a-select>
        </SelectWrapperStyle>
        <br />
        <br />

        <span class="error-messages" 
            v-if="v$.attributesValues.$error">
            {{ v$.attributesValues.$errors[0].$message }}
        </span>

        <div class="record-form-actions text-left">
            <sdButton 
                size="default" 
                htmlType="Save" 
                type="primary"
                @click="saveAttributes"
            >
                {{ 'Submit' }}
            </sdButton>
        </div>

        <br />

        <div v-if="isLoading" class="spin">
          <a-spin />
        </div>

        <div>
          <TableWrapper class="table-data-view table-responsive">
            <a-table
              :pagination="{ pageSize: 10, showSizeChanger: true }"
              :dataSource="dataSource"
              :columns="columns"
            />
          </TableWrapper>
        </div>
    </sdCards>
</a-col>
</a-row>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue';
import { SelectWrapperStyle, TableWrapper } from '../styled';
import ProductService from '../../services/ProductService';
import AttributesMapsService from '../../services/AttributesMapsService';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, maxLength } from '@vuelidate/validators';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'AttributesMapsComponent',
    components: {
        SelectWrapperStyle, 
        TableWrapper
    },
    
    setup() {
        const attributes = ref([]);
        const attributesValues = ref([]);
        const attributesMaps = ref([]);

        const { state, dispatch } = useStore();

        const isLoading = computed(() => state.actions.isLoading);

        const columns = [
            {
                title: 'Attribute',
                dataIndex: 'attribute',
                key: 'attribute',
            },

            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
            },
        ]

        onMounted(async () => {
            await getProductAttributes();
            await getAttributesMaps();
        });

        const getProductAttributes = async () => {
            const response = (await ProductService.getAttributes()).data;

            attributes.value = response;
        };

        const getAttributesMaps = async () => {
            dispatch("actions/setLoading", true);

            const response = (await AttributesMapsService.getAttributesMaps()).data;

            attributesMaps.value = response;

            dispatch("actions/setLoading", false);
        }

        const saveAttributes = async () => {
            if (! await v$._value.$validate()) return;

            await AttributesMapsService.saveAttributes({attributes: attributesValues.value});

            await window.showAlert(
                "success",
                "Success",
                "Attributes created successfully!"
            );

            attributesMaps.value = [];

            await getAttributesMaps();
        };

        const handleDelete = async (id) => {
            await AttributesMapsService.deleteAttributeMap(id);

            await window.showAlert(
                "success",
                "Success",
                "Attributes deleted successfully!"
            );

            await getAttributesMaps();
        }

        const dataSource = computed(() => {
            return attributesMaps.value.map((attribute, key) => {
                return {
                    key: key + 1,
                    attribute: attribute.attribute,
                    actions: (
                        <div class="table-actions">
                            <router-link class="delete" to="#" onClick={() => handleDelete(attribute.id)}>
                                <sdFeatherIcons type="delete" size={14} />
                            </router-link>
                        </div>
                    )
                }
            });
        });

        const rules = computed(() => ({
            attributesValues: {
                required: helpers.withMessage(
                    'Minimum 1 attribute is required',
                    required
                ),
                maxLength: maxLength(7)
            }
        }));

        const v$ = useVuelidate(rules, { attributesValues });

        return {
            attributes,
            attributesValues,
            saveAttributes,
            dataSource,
            columns,
            isLoading,
            v$
        }
    }
});
</script>