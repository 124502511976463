<template>
    <sdPageHeader title="Attributes Maps"></sdPageHeader>
    <Main>
        <AttributesMapsComponent />
    </Main>
</template>
<script>
import { Main } from '../styled';
import AttributesMapsComponent from './AttributesMapsComponent.vue';
export default {
    components: {
        Main,
        AttributesMapsComponent
    }
}
</script>